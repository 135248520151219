import Vue from "vue";
import Router from "vue-router";
import Controller from "./controller.js";
import EsqueciSenha from "@/components/autenticacao/EsqueciSenha";
import Login from "@/components/autenticacao/Login";
import RecuperarSenha from "@/components/autenticacao/RecuperarSenha";
import Portal from "@/components/portal/Portal";
import ErroPaginaNaoEncontrada from "@/components/paginas-erro/ErroPaginaNaoEncontrada";
import Usuarios from "@/components/usuarios/Usuarios";
import CadastroUsuario from "@/components/usuarios/CadastroUsuario";
import Empresas from "@/components/empresas/Empresas";
import CadastroEmpresa from "@/components/empresas/CadastroEmpresa";

import AdminContas from "@/components/contas/admin/Contas";
import EmpresaContas from "@/components/contas/empresa/Contas";

import Funcionarios from "@/components/funcionario/Funcionarios";
import CadastroFuncionario from "@/components/funcionario/CadastroFuncionario";
import CadastroFuncionarioLote from "@/components/funcionario/CadastroFuncionarioLote";

import PropostasAdmin from "@/components/propostas/PropostasAdmin";
import PropostasFuncionario from "@/components/propostas/PropostasFuncionario";

import SimulacoesFuncionario from "@/components/simulacoes/SimulacoesFuncionario";
import ContaHome from "@/components/conta-cartos/ContaHome";

import FolhaPagamento from "@/components/folha/FolhaPagamento";
import CadastroFolhaPagamentoArquivo from "@/components/folha/CadastroFolhaPagamentoArquivo";


Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { requiresAuth: false, mostraSideMenu: false }
    },
    {
      path: "/",
      name: "Portal",
      alias: "/portal",
      component: Portal,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/usuarios",
      name: "Usuarios",
      component: Usuarios,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/usuario/cadastro",
      name: "CadastroUsuario",
      component: CadastroUsuario,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/empresas",
      name: "Empresas",
      component: Empresas,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/empresa/cadastro",
      name: "CadastroEmpresa",
      component: CadastroEmpresa,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/admin/contas",
      name: "AdminContas",
      component: AdminContas,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/empresa/contas",
      name: "EmpresaContas",
      component: EmpresaContas,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/funcionarios",
      name: "Funcionarios",
      component: Funcionarios,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/funcionario/cadastro",
      name: "CadastroFuncionario",
      component: CadastroFuncionario,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/funcionario/lote/cadastro",
      name: "CadastroFuncionarioLote",
      component: CadastroFuncionarioLote,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/funcionario/simulacoes",
      name: "SimulacoesFuncionario",
      component: SimulacoesFuncionario,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/funcionario/propostas",
      name: "PropostasFuncionario",
      component: PropostasFuncionario,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/empresa/conta",
      name: "ContaHome",
      component: ContaHome,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/gestao/propostas",
      name: "PropostasAdmin",
      component: PropostasAdmin,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/folha",
      name: "FolhaPagamento",
      component: FolhaPagamento,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/folha/lote",
      name: "CadastroFolhaPagamentoArquivo",
      component: CadastroFolhaPagamentoArquivo,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/RecuperarSenha/:token",
      name: "RecuperarSenha",
      meta: {
        title: "Recuperação de Senha",
        navbartitle: "Recuperação de Senha",
        requiresAuth: false
      },
      component: RecuperarSenha
    },
    {
      path: "/EsqueciSenha",
      name: "EsqueciSenha",
      meta: {
        title: "Recuperação de Senha",
        navbartitle: "Recuperação de Senha",
        requiresAuth: false,
        hideLogin: true
      },
      component: EsqueciSenha
    },
    {
      path: "*",
      name: "ErroPaginaNaoEncontrada",
      component: ErroPaginaNaoEncontrada
    },
  ],
  mode: "history"
});

router.beforeEach((to, from, next) => {
  Controller.auth(to, from, next);
  Controller.permissaoAcesso(to, from, next);

  document.title = `Pague Folha - ${to.meta.navbartitle ?? to.name}`;
});

export default router;
