<template>
    <v-container>
        <v-layout align-center>
            <v-flex xs10>
                <h3 class="secondary--text">Conta</h3>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 md4 text-xs-right>
                <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
            </v-flex>
            <v-flex xs12 md1 text-xs-right>
                <v-tooltip color="secondary" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" slot="activator" dark small color="secondary" depressed
                            @click="abrirQrCodeModal">
                            <v-icon>mdi-qrcode</v-icon>
                        </v-btn>
                    </template>
                    <span>Adicionar Valor a Conta</span>
                </v-tooltip>
            </v-flex>
            <v-flex xs12 md1 text-xs-right>
                <v-tooltip color="secondary" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" slot="activator" dark small color="secondary" depressed @click="baixaOFX">
                            <v-icon>mdi-cloud-download-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Download OFX</span>
                </v-tooltip>
            </v-flex>
        </v-layout>
        <v-layout row wrap>
            <v-flex xs12 lg3>
                <v-card color="purple darken-2" class="white--text">
                    <v-card-title primary-title>
                        <div>
                            <div class="headline">Saldo</div>
                            <span class="subheading mr-2">{{ customFormatComputed.formatarReais(dashboard.sdpd)
                                }}</span>
                            <br />
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
            <v-flex xs12 lg3>
                <v-card color="blue-grey darken-1" class="white--text">
                    <v-card-title primary-title>
                        <div>
                            <div class="headline">Saldo a Utilizar</div>
                            <span class="subheading mr-2">{{ customFormatComputed.formatarReais(dashboard.saldoUtilizado)
                                }}</span>
                            <br />
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
            <v-flex xs12 lg3>
                <v-card color="cyan darken-2" class="white--text">
                    <v-card-title primary-title>
                        <div>
                            <div class="headline">Número de Contas</div>
                            <span class="subheading mr-2">{{ dashboard.contas
                                }}</span>
                            <br />
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
            <v-flex xs12 lg3>
                <v-card color="blue darken-2" class="white--text">
                    <v-card-title primary-title>
                        <div>
                            <div class="headline">Valor de Crédito</div>
                            <span class="subheading mr-2">{{ customFormatComputed.formatarReais(dashboard.valorCredito)
                                }}</span>
                            <br />
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout justify-space-around row wrap>
            <v-flex xs12>
                <v-card class="default-border" flat text>
                    <v-container grid-list-lg>
                        <v-layout row wrap>
                            <v-flex xs12 md2>
                                <v-menu ref="menuCalendarDataInicial" v-model="menuCalendarDataInicial"
                                    :close-on-content-click="false" :return-value.sync="pesquisaModel.startDate"
                                    :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dataInicialFormatada" label="Data inicial"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                            :rules="regras.branco"></v-text-field>
                                    </template>
                                    <v-date-picker ref="picker" :active-picker.sync="activePickerStartDate"
                                        v-model="pesquisaModel.startDate" locale="pt-br" :min="new Date(
                    new Date().setFullYear(new Date().getFullYear() - 1)
                )
                    .toISOString()
                    .substring(0, 10)" :max="new Date(
                    new Date().setFullYear(new Date().getFullYear() + 1)
                )
                    .toISOString()
                    .substring(0, 10)
                    " required @input="
                    $refs.menuCalendarDataInicial.save(
                        pesquisaModel.startDate
                    )
                    "></v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs12 md2>
                                <v-menu ref="menuCalendarDatafinal" v-model="menuCalendarDatafinal"
                                    :close-on-content-click="false" :return-value.sync="pesquisaModel.endDate"
                                    :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dataFinalFormatada" label="Data final"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                            :rules="regras.branco"></v-text-field>
                                    </template>
                                    <v-date-picker ref="picker" :active-picker.sync="activePickerEndDate"
                                        v-model="pesquisaModel.endDate" locale="pt-br" :min="new Date(
                    new Date().setFullYear(new Date().getFullYear() - 1)
                )
                    .toISOString()
                    .substring(0, 10)" :max="new Date(
                    new Date().setFullYear(new Date().getFullYear() + 1)
                )
                    .toISOString()
                    .substring(0, 10)
                    " required @input="
                    $refs.menuCalendarDatafinal.save(
                        pesquisaModel.endDate
                    )
                    "></v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs12 md2>
                                <v-btn id="pesquisar" color="secondary" depressed @click="buscar">Pesquisar</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>
            <v-flex xs12>
                <v-card class="default-border" flat text>
                    <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas"
                        :search="filtro" :sort-by="['createdAt']" :sort-desc="[true]" :items-per-page="20"
                        :footer-props="{
                    itemsPerPageAllText: 'Todos',
                    itemsPerPageText: 'Itens por página',
                }">
                        <template v-slot:[`item.amount`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.amount) }}
                        </template>
                        <template v-slot:[`item.numeroDocumento`]="{ item }">
                            {{ customFormatComputed.formatCPFCNPJNumber(item.numeroDocumento) }}
                        </template>
                        <template v-slot:[`item.type`]="{ item }">
                            {{ item.type == "debit" ? "Débito" : "Crédito" }}
                        </template>
                        <template v-slot:[`item.createdAt`]="{ item }">
                            {{
                    customFormatComputed.toLocaleDateTimePtBrString(item.createdAt)
                }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
        <template>
            <v-dialog v-model="dialogQRCode" persistent>
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Gerar QRCode</span>
                    </v-card-title>
                    <v-card-text>
                        <div v-if="!atulizarTela">
                            <v-form v-model="formularioCadastroValido" ref="formularioCadastroRef">
                                <v-container grid-list-lg>
                                    <v-layout row wrap>
                                        <v-flex xs12 md6>
                                            <v-text-field ref="txtprecoFormatado" v-model.lazy="precoFormatado"
                                                color="secondary" prepend-icon="attach_money" label="Valor" required
                                                v-money="money" :rules="regras.branco">
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-form>
                        </div>
                        <div v-else>
                            <v-container grid-list-lg>
                                <v-layout row wrap>
                                    <v-flex xs12 text-xs-center>
                                        <div>
                                            <img :src="'data:image/png;base64,' + retornoModel.imagem" width="350"
                                                alt="qrcode">
                                        </div>
                                    </v-flex>
                                    <v-flex xs12>
                                        {{ retornoModel.EMV }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-btn tile icon @click="utilsComputed.copy(retornoModel.EMV)"><v-icon
                                                style="font-size: 26px">mdi-content-copy</v-icon></v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="closeQrCodeModal">
                            Fechar
                        </v-btn>
                        <v-btn color="secondary" depressed :disabled="disableBtnFinalizar" @click="finalizar">
                            Gerar
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-dialog>
        </template>
        <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
            v-model="snackbarModel.mostrar" bottom multi-line>
            {{ snackbarModel.text }}
            <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";
import { VMoney } from 'v-money'
import utils from "@/helpers/utils.js";

export default {
    name: "ContaHome",
    data: () => ({
        dialogQRCode: false,
        disableBtnFinalizar: false,
        precoFormatado: null,
        formularioCadastroValido: false,
        menuCalendarDataInicial: false,
        dataInicialFormatada: null,
        activePickerStartDate: null,
        menuCalendarDatafinal: false,
        dataFinalFormatada: null,
        activePickerEndDate: null,
        atulizarTela: false,
        snackbarModel: {
            mostrar: false,
            sucesso: false,
            text: "",
        },
        dashboard: {
            sdpd: 0,
            saldoUtilizado: 0,
            contas: 0,
            valorCredito: 0
        },
        cadastroModel: {
            valor: 0
        },
        pesquisaModel:
        {
            startDate: null,
            endDate: null,
        },
        retornoModel: {
            EMV: null,
            imagem: null,
        },
        money: {
            decimal: ',',
            thousands: '.',
            prefix: 'R$ ',
            precision: 2,
            masked: false
        },
        filtro: "",
        headers: [
            { text: "Data", value: "createdAt" },
            { text: "Tipo", value: "type" },
            { text: "Categoria", value: "category" },
            { text: "Nome", value: "name" },
            { text: "Documento", value: "numeroDocumento" },
            { text: "Banco", value: "bankName" },
            { text: "Agência / Nº da Conta", value: "agenciaCC" },
            { text: "Valor", value: "amount" },
        ],
        listaModelo: [],
        regras: {
            branco: [(v) => !!v || "Você não pode deixar este campo em branco"],
        }
    }),
    directives: { money: VMoney },
    mounted: function () {
        this.pesquisaModel.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)
        this.pesquisaModel.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)
        this.buscar()
        this.buscarSaldo();
        this.buscaCountContas();
        this.externalSnackbarMessage()
    },
    watch: {
        precoFormatado: function (val) {
            this.cadastroModel.valor = customFormat.removeMoneyFormat(val);
        },
        "pesquisaModel.startDate": function (val) {
            this.dataInicialFormatada = val
                ? new Date(val).toLocaleDateString("pt-br", { timeZone: "UTC" })
                : "";
        },
        "pesquisaModel.endDate": function (val) {
            this.dataFinalFormatada = val
                ? new Date(val).toLocaleDateString("pt-br", { timeZone: "UTC" })
                : "";
        },
    },
    computed: {
        customFormatComputed: function () {
            return customFormat;
        },
        cipherComputed: function () {
            return cipher;
        },
        formValid: function () {
            return this.formularioCadastroValido;
        },
        utilsComputed: function () {
            return utils;
        }
    },
    methods: {
        abrirQrCodeModal() {
            const self = this;
            self.dialogQRCode = true;
        },
        closeQrCodeModal() {
            const self = this;
            self.dialogQRCode = false;
        },
        baixaOFX() {
            const self = this;
            self.$store.commit("startLoading");
            self.$http
                .get(
                    `${process.env.VUE_APP_URL_API}/conta/extrato/download/ofx?startDate=${self.pesquisaModel.startDate}&endDate=${self.pesquisaModel.endDate}`,
                    {
                        responseType: "blob",
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then(function (response) {
                    let blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'extrato_bancario.ofx';
                    link.click();
                    self.$store.commit("endLoading");
                }).catch(function (error) {
                    console.error(error)
                    self.$store.commit("endLoading");
                });
        },
        buscaCountContas() {
            const self = this;

            self.$http
                .get(
                    `${process.env.VUE_APP_URL_API}/portal/busca/count/contas`,
                    {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then(function (response) {
                    self.dashboard.contas = response.data
                }).catch(function (error) {
                    console.error(error)
                });
        },
        finalizar() {
            const self = this;
            self.$store.commit("startLoading");
            if (self.$refs.formularioCadastroRef.validate()) {
                self.$http
                    .post(
                        `${process.env.VUE_APP_URL_API}/conta/gera/qrcode-dinamico`,
                        self.cadastroModel,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        const data = response.data;
                        if (data.sucesso) {
                            self.disableBtnFinalizar = data.sucesso;
                            self.atulizarTela = data.sucesso;
                            self.retornoModel.EMV = data.QRCode.EMV;
                            self.retornoModel.imagem = data.QRCode.Imagem;

                            self.snackbarModel.text = data.mensagem;
                            self.snackbarModel.mostrar = true;
                            self.snackbarModel.sucesso = data.sucesso;
                        } else {
                            self.snackbarModel.text = data.mensagem;
                            self.snackbarModel.mostrar = true;
                            self.snackbarModel.sucesso = data.sucesso;
                            self.disableBtnFinalizar = data.sucesso;
                        }
                        self.$store.commit("endLoading");
                    })
                    .catch(function (error) {
                        self.snackbarModel.text = error.response.data.mensagem;
                        self.snackbarModel.sucesso = false;
                        self.snackbarModel.mostrar = true;
                        self.disableBtnFinalizar = false;
                        self.$store.commit("endLoading");
                    });
            }
        },
        buscar() {
            const self = this;
            self.$store.commit("startLoading");
            self.$http
                .get(
                    `${process.env.VUE_APP_URL_API}/conta/busca/extrato?startDate=${self.pesquisaModel.startDate}&endDate=${self.pesquisaModel.endDate}`,
                    {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then(function (response) {
                    if (response.data.sucesso) {
                        self.listaModelo = [];
                        response.data.rows.forEach(e => {
                            let nome = null;
                            let numeroDocumento = null;
                            let agenciaCC = null;

                            switch (e.category) {
                                case "TEV":
                                    nome = e.payer.name
                                    numeroDocumento = e.payer.documentNumber
                                    agenciaCC = e.transactionData.agency + "/" + e.transactionData.accountNumber
                                    break;
                                case "PIX":
                                    if (e.type != "credit") {
                                        nome = e.receiver.name
                                        numeroDocumento = e.receiver.documentNumber
                                        agenciaCC = e.transactionData.agency + "/" + e.transactionData.account
                                    } else {
                                        nome = e.payer.name
                                        numeroDocumento = e.payer.documentNumber
                                        agenciaCC = e.transactionData.agencyPayer + "/" + e.transactionData.accountPayer
                                    }
                                    break;
                            }

                            self.listaModelo.push({
                                numeroDocumento: numeroDocumento,
                                name: nome,
                                amount: e.amount,
                                type: e.type,
                                category: e.category,
                                agenciaCC: agenciaCC,
                                createdAt: e.createdAt,
                                bankName: e.transactionData.bankName
                            })
                        });
                    }
                    self.$store.commit("endLoading");
                }).catch(function (error) {
                    console.log(error.response);
                    self.snackbarModel.text = error.response.data.mensagem;
                    self.snackbarModel.sucesso = false;
                    self.snackbarModel.mostrar = true;
                    self.disableBtnFinalizar = false;
                    self.$store.commit("endLoading");
                });
        },
        buscarSaldo() {
            const self = this;
            self.$http
                .get(
                    `${process.env.VUE_APP_URL_API}/portal/busca/saldo`,
                    {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then(function (response) {
                    self.dashboard.sdpd = response.data;
                }).catch(function (error) {
                    console.log(error.response);
                });
        },
        externalSnackbarMessage: function () {
            const self = this;
            if (self.$route.params.snackbarMostrar) {
                self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
                self.snackbarModel.text = self.$route.params.snackbarText;
                self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
            }
        },
        go: function (routeName, routeParams) {
            const self = this;
            if (!routeParams) {
                self.$router.push({ name: routeName });
            } else {
                self.$router.push({ name: routeName, params: routeParams });
            }
        }
    }
};
</script>