const CustomFormat = {
  removeMoneyFormat: function (value) {
    if (value) {
      return parseInt(value.replace('R$ ', '').replace(',', '').replace('.', ''));
    } else {
      return undefined;
    }
  },
  toLocaleDateString: function (value) {
    if (value) {
      const date = new Date(value);
      const offsetDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = offsetDate.getDate().toString().padStart(2, '0');
      const month = (offsetDate.getMonth() + 1).toString().padStart(2, '0');
      const year = offsetDate.getFullYear();
      return `${day}/${month}/${year}`;
    } else {
      return undefined;
    }
  },
  toLocaleDateTimeString: function (value) {
    if (value) {
      const date = new Date(value);
      const offsetDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = offsetDate.getDate().toString().padStart(2, '0');
      const month = (offsetDate.getMonth() + 1).toString().padStart(2, '0');
      const year = offsetDate.getFullYear();
      const hours = offsetDate.getHours().toString().padStart(2, '0');
      const minutes = offsetDate.getMinutes().toString().padStart(2, '0');
      const seconds = offsetDate.getSeconds().toString().padStart(2, '0');
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    } else {
      return undefined;
    }
  },
  toLocaleDateTimePtBrString: function (value) {
    if (value) {
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    } else {
      return undefined;
    }
  },
  parseDateToFormatedString: function (value) { //De aaaa-mm-dd para dd/mm/aaaa
    if (value) {
      const [ano, mes, dia] = value.toString().split('-');
      return `${dia}/${mes}/${ano}`;
    } else {
      return undefined;
    }
  },
  parseDateToInt: function (value) { //De aaaa-mm-dd para um int aaaammdd
    if (value) {
      const [ano, mes, dia] = value.toString().split('-');
      return parseInt(ano + mes + dia);
    } else {
      return undefined;
    }
  },
  parseFormatedDateToInt: function (value) { //De dd/mm/aaaa para um int aaaammdd
    if (value) {
      const dateOnly = value.substring(0, 10);
      const [dia, mes, ano] = dateOnly.split('/');
      return parseInt(ano + mes + dia);
    } else {
      return undefined;
    }
  },
  parseFormatedDateTimeToInt: function (value) { //De 'dd/mm/aaaa hh:mm:ss' para um int aaaammddhhmmss
    if (value) {
      const dateOnly = value.substring(0, 10);
      const [dia, mes, ano] = dateOnly.split('/');
      const timeOnly = value.substring(11, 19);
      const [hora, minuto, segundo] = timeOnly.split(':');
      return parseInt(ano + mes + dia + hora + minuto + segundo);
    } else {
      return undefined;
    }
  },
  formatPhoneNumber: function (phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = null;

    if (cleaned.length == 11) {
      match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    } else if (cleaned.length == 10) {
      match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
    } else {
      return phoneNumberString;
    }

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
  },
  formatCPFCNPJNumber: function (cpfcnpjNumberString) {
    var cleaned = ('' + cpfcnpjNumberString).replace(/\D/g, '');
    var match = null;

    if (cleaned.length == 11) {
      match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
      if (match) {
        return match[1] + '.' + match[2] + '.' + match[3] + '-' + match[4];
      }
    } else if (cleaned.length == 14) {
      match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
      if (match) {
        return match[1] + '.' + match[2] + '.' + match[3] + '/' + match[4] + '-' + match[5];
      }
    }
    return cpfcnpjNumberString;
  },
  removerAcentos: function (text) {
    text = text.toLowerCase();
    text = text.replace(new RegExp('[ÁÀÂÃÄ]', 'gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊË]', 'gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎÏ]', 'gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕÖØ]', 'gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛÜ]', 'gi'), 'u');
    text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
    return text;
  },
  compareNome: function (a, b) {
    const bandA = a.nome.toUpperCase();
    const bandB = b.nome.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  },
  parseNumber: function (stringNum) {
    stringNum = stringNum || 0;
    return (parseFloat(stringNum).toFixed(2) + "").replace(".", ",");
  },
  formatarReais(valorCentavos) {
    const valorReais = valorCentavos / 100;
    return valorReais.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  }
};

export default CustomFormat;
